





































































































import Vue from "vue";
import {IFeedbackSetting} from "@/interfaces/dtos/feedback/IFeedbackSetting.ts";
import {Component} from "vue-property-decorator";
import feedbackService from "@/services/services/feedback.services";

@Component({
  components: { }
})
export default class FeedBack extends Vue {
  eventId = "";
  serviceId = "";
  feedbackSetting = {} as IFeedbackSetting;
  loading = true;
  googleReview = false;
  extractions = 0;
  extractionsText = "";
  rating = 0;

  async created() {
    console.log('entro al create');
    this.eventId = this.$route.params['eventId'];
    this.serviceId = this.$route.params['serviceId'];
    this.loading = true;
    // sino pasaron ninguno busco por defecto un servicio q sea Facial
    if (!this.serviceId) { this.serviceId = '5edece04a6b73d178dc4cd46';}
    const res = await feedbackService.getSettingFeedback(this.serviceId);
    if (res.success) {
      this.feedbackSetting = res.result ?? {} as IFeedbackSetting;
      this.extractions = this.feedbackSetting.betterOption ?? 0;
    }
    console.log('result --> ', res);
    // await this.syncReview();
    this.loading = false;
  }

  async submitForm() {
    console.log('entro al submit');
    console.log('rating-> ', this.rating);
    this.loading = true;
    if ( this.rating != 0) {
      this.extractionsText = this.feedbackSetting.feedbackOptions[this.extractions];
      console.log('extractionsText-> ', this.extractionsText);
      const res = await feedbackService.postReviewFeedback({
        eventId: this.eventId,
        rating: this.rating,
        extractions: this.extractionsText
      });
      if (res.success && this.rating >= 5 && this.extractions === this.feedbackSetting.betterOption) {
        this.googleReview =true;
      }
      this.$notify({
        type: "success",
        title: "Success",
        text: 'Thank you for filling out the survey, your input matters to us'
      });
      console.log('res-> ', res);
    } else {
      this.$notify({
        type: "error",
        title: "Error",
        text: 'You must complete the starts rating to continue'
      });
    }
    this.loading= false;
  }

  async syncReview() {
    this.loading = true;
    const res = await feedbackService.getSyncReview(this.eventId);
    if (res.success && res.result) {
      this.rating = res.result.stars;
    }
    this.loading = false;
  }
}
