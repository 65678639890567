import * as buildQuery from 'odata-query';
import {QueryFilter} from "@/interfaces/odata/QueryFilter";

export type SortDirection = 'Ascending' | 'Descending';

export interface OrderDefinition {
  field: string;
  direction: SortDirection;
  // useProfile?: boolean;
}

export interface QueryParameters {
  [key: string]: string | string[] | number | number[] | undefined;
}

export interface SortProfile {
  profile: string;
  direction: SortDirection;
}

export interface Query {
  searchQuery?: string;
  orderBy?: OrderDefinition[];
  page: number;
  perPage: number;
  // parameters?: QueryParameters;
  filter?: QueryFilter;
}

export interface QueryResponse<T> {
  totalRecords: number;
  data: Array<T>;
}

export class ODataUtils {
  public static buildUrl(query: Query) {
    const top = query.perPage || 10;
    const page = Math.max(query.page, 1);
    const skip = top * (page - 1);
    const oDataQuery = { skip, top } as any;

    if (query.searchQuery)
      oDataQuery['search'] = query.searchQuery;
    if (query.orderBy && query.orderBy.length)
      oDataQuery['orderBy'] = query.orderBy.map(x => `${x.field} ${x.direction == "Ascending" ? 'asc' : 'desc'}`);

    if (query.filter)
      oDataQuery['filter'] = query.filter;
    return buildQuery.default(oDataQuery);
  }
}
