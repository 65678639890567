import {container, resolve} from "@/utils/inversify.config";
import {IHttpResponse} from "@/interfaces/response/IHttpResponse";
import {IPaginateResponse} from "@/interfaces/response/IPaginateResponse";
import httpService, {HttpService} from "@/services/core/http/http.service";
import {ID} from "@/utils/constants";
import {ODataUtils, Query, QueryResponse} from "@/services/core/odata/odata.services";
import {camelizeObject, clone} from "@/utils/objects";
// import {injectable} from "inversify";
// import 'reflect-metadata';

export interface CrudServicesInterface {
    getAll<T>(query: Query): Promise<IHttpResponse<IPaginateResponse<T>>>;
    getOne<T>(id: ID): Promise<IHttpResponse<T>>;
    create<T>(input: any): Promise<IHttpResponse<T>>;
    update<T>(id: ID, input: any): Promise<IHttpResponse<T>>;
    delete<T>(id: ID): Promise<IHttpResponse<T>>;
}

// @injectable()
export class CrudServices<Titem, Tinput> {
    // @resolve(HttpService)
    // protected readonly httpService!: HttpService;
    constructor(protected readonly serviceUrl: string) {
    }
    readonly apiPrefix = 'api/';
    readonly odataPrefix = 'odata/';

    async getAll(query: Query): Promise<IHttpResponse<IPaginateResponse<Titem>>> {
        // console.log('query--> ', query);
        const response = await httpService.get<QueryResponse<Titem>>(`${this.apiPrefix}${this.serviceUrl}${ODataUtils.buildUrl(query)}`);
        let result: IPaginateResponse<Titem> | undefined = undefined;
        if(response.success && response.result) {
            const totalRecords = response.result.totalRecords;
            const totalPages = Math.ceil(totalRecords/(query.perPage || 10));
            result = {
                totalPages,
                totalRecords,
                data: response.result.data,
                pageNumber: totalPages ? Math.min(query.page || 0, totalPages) : 0,
                pageSize: query.perPage || 10,
            };
        }
        return {
            success: response.success,
            message: response.message ?? undefined,
            result,
        };
    }
    async getOne(id: ID): Promise<IHttpResponse<Titem>> {
        return await httpService.get<Titem>(`${this.apiPrefix}${this.serviceUrl}/${id}`);
    }
    async create(input: Tinput): Promise<IHttpResponse<Titem>> {
        console.log('input create -> ', input);
        return await httpService.post<Tinput, Titem>(this.apiPrefix + this.serviceUrl, input);
    }
    async update(id: ID, input: Partial<Titem>): Promise<IHttpResponse<Titem>> {
        return await httpService.put<Partial<Titem>, Titem>(`${this.apiPrefix}${this.serviceUrl}/${id}`, input);
    }
    async delete(id: ID): Promise<IHttpResponse<Titem>> {
        return await httpService.delete<any, Titem>(`${this.apiPrefix}${this.serviceUrl}/${id}`);
    }
}
