import {CrudServices} from "@/services/core/crud.service";
import httpService from "@/services/core/http/http.service";
import {ODataUtils, Query, QueryResponse} from "@/services/core/odata/odata.services";
import {IFeedbackSetting} from "@/interfaces/dtos/feedback/IFeedbackSetting";
import {FeedbackInputType} from "@/interfaces/dtos/feedback/IFeedbackInputType";
import {IFeedbackSettingInput} from "@/interfaces/dtos/feedback/IFeedbackSettingInput";
import {IHttpResponse} from "@/interfaces/response/IHttpResponse";
import {API_KEY} from "@/utils/constants";
import Axios from "axios";

export class FeedbackServices extends CrudServices<IFeedbackSetting, IFeedbackSettingInput> {
	constructor(serviceUrl = "review") {
		super(serviceUrl)
	}
	async getSettingFeedback(settingId: string): Promise<IHttpResponse<IFeedbackSetting>> {
		console.log('query--> ', settingId);
		const response = await httpService.get<IFeedbackSetting>(`${this.serviceUrl}/feedbackSetting/${settingId}`);
		let result: IFeedbackSetting | undefined = undefined;
		if (response.success && response.result) {
			result = response.result;
		}
		return {
			success: response.success,
			message: response.message ?? undefined,
			result,
		};
	}
	
	async postReviewFeedback(query: FeedbackInputType): Promise<IHttpResponse<any>> {
		console.log('query--> ', query);
		const response = await httpService.post<FeedbackInputType, any>(`${this.serviceUrl}/feedback`, query);
		// const response = Axios.post(`http://localhost:3000/${this.serviceUrl}/feedback`, query, {headers: headers});
		let result: any | undefined = undefined;
		if (response.success && response.result) {
			result = response.result;
		}
		return {
			success: response.success,
			message: response.message ?? undefined,
			result,
		};
	}
	
	async getSyncReview(eventId: string): Promise<IHttpResponse<any>> {
		const response = await httpService.get<any>(`${this.serviceUrl}/feedbackReload/${eventId}`);
		let result: IFeedbackSetting | undefined = undefined;
		if (response.success && response.result) {
			result = response.result;
		}
		return {
			success: response.success,
			message: response.message ?? undefined,
			result,
		};
	}
}

export default new FeedbackServices();